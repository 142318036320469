.headerss {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* margin-top: 7rem; */
    color: rgb(255, 255, 255);
    margin-bottom: 1rem;
}

.heading_segment {
    font-family: Helvetica;
    font-size: 1.7rem;
    font-size: 32px;
    text-align: center;
    letter-spacing: 3px;
    /* padding-bottom: 2px; */
    padding-top: 1em;
    /* border-bottom: 2px solid #7e7e7e; */
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    margin: 1rem 1rem;
    color: rgb(255, 255, 255);
    /* color: var(--white); */
}
/* .headerss button{
    border-radius: 30px;
    width:100%;
} */

#heading_sponsors_page{
    margin-bottom: 2rem;
}

/* ************** Styling in particular heading ***************** */

#headingsOfAll :not(.footer_header){
    color: (rgb(255, 255, 255)8, 36, 36);
}

@media screen and  (max-width:285px) {

   .headerss h2{

        font-size: 1.2rem !important;

    }
    
}

@media screen and (min-width:286px)  and  (max-width:323px) {

    .headerss h2{

        font-size: 1.3rem !important;

    }
    
}

@media screen and (min-width:324px) and (max-width:375px) {

    .headerss h2{

        font-size: 1.55rem !important;

    }
    
}

@media screen and (min-width:376px) and (max-width:415px) {

    .headerss h2{

        font-size: 1.7rem !important;

    }
    
}

@media screen and (max-width:578px) {
    .heading_segment {
       
        font-size: 1rem !important;
        font-weight: 400;
    }
}