/* insta.css */

.main_data {
  display: flex;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
  position: relative;
  max-width:1200px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.feed_img {
  cursor: pointer;
  position: relative;
  overflow: hidden;

}
/* insta.css */


.main_insta {
  flex-shrink: 0;
   overflow: hidden;
    flex: 0 0 auto;
    width: 100%; /* Set the width of each slide to 100% */
    margin-right: 0; /* No margin between slides */
  
}



.feed_img img,
.feed_img video {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  /* overflow: hidden; */
}


.main_insta {
  flex: 0 0 auto;
  width: 350px; /* Set the width of each slide */
  margin-right: 20px;
  height: 350px;
  overflow: hidden !important;
}

.feed_img {
  position: relative;
  overflow: hidden;
  cursor: grab;
 
}

.feed_img img,
.feed_img video {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure images/videos fill the container */
  display: block;
}

/* Make the element draggable */
.feed_img:active {
  cursor: grabbing;
}

@media screen and (max-width:568px) {
  .main_data {
    max-width: 100% !important; /* Adjust the max-width for smaller screens if needed */
  }

  .feed_img {
    width: 100% !important; /* Set the width of the image or video to fill the container */
    height: auto !important; /* Allow the height to adjust proportionally */
  }
  .main_insta {
    flex: 0 0 auto;
    width: 100% !important; /* Set the width of each slide */
    margin-right: -10px;
    height: 400px !important;
    overflow: hidden !important;
  }
}
@media screen and (max-width:910px) {
  .main_data {
    max-width: 100% !important; /* Adjust the max-width for smaller screens if needed */
  }

  .feed_img {
    width: 100% !important; /* Set the width of the image or video to fill the container */
    height: auto !important; /* Allow the height to adjust proportionally */
  }
  .main_insta {
    flex: 0 0 auto;
    width: 100% !important; /* Set the width of each slide */
    margin-right: -10px;
    height: 800px ;
    overflow: hidden !important;
  }
}