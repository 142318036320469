/* VideoPage.css */
.video-container {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* height: 100vh; */
    overflow: hidden;
    background-color: black;
    position: relative;
  }
  
  video {
    width: 100%;
   
    height: auto;
    object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  }

  @media screen and (max-width:768px) {
    .video-container{
      width: 100%;
      height: auto;
    }
  }
  
  .play-pause-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: 2px solid white;
    border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    display: none;
  }
  
  .playing {
    display: none;
  }
  
  .paused {
    display: block;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: 2px solid white;
    border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  