@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);

.hovering{
  position: absolute;
  top: 490px; 
  width: 150px;
  right: 10%;
  transform: translateX(-50%);
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  white-space: nowrap;
  padding: 5px;
  font-family: Helvetica;
  border-radius: 10px;
  z-index: 1;
  font-size: 20px;
}

.main_color_div{
  background-color: rgb(34, 33, 33);
  }

.green_evvehicle img {
  margin: -19rem;
  width: 60% !important;
  transition: transform 0.5s ease-in-out; /* Add transition property */
}
.blacks-container{
  display:block;
  margin: 0 auto;
  justify-content:center;
  align-items: center;
  background-color: rgb(34, 33, 33);
  /* padding: 0 20px; */
}

.iconss tr td{
  justify-content: center;
  text-align: center;
}

.main_color_div{
  /* margin-top: 1em; */
}
.white-box {
  position: relative;
  display:flex;
  width: 100%;
  z-index: 0;
  /* margin-top: 3rem !important; */
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  /* background-color: rgb(255, 255, 255); */
}
.white-box img{
  width:100%;
  height:auto;
}

.green_evvehicle {
  position: absolute;
  max-width: 150%;
  /* display: inline; */
   /* top: 50px; */
}
.green_evvehicle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.green_evvehicle img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.circle_main {
  position: absolute;
  top: 0;
  left: 80;
  padding: 2rem;
  padding-top: 12em;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: #3498db; /* Change the background color as needed */
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: inset 1px 2px 5px rgba(0, 0, 0, 0.733);
  border: solid 0.1px rgba(255, 255, 255, 0.37);
}

.circle:hover {
  box-shadow: inset 0 5px 5px rgba(245, 222, 230, 0.16); /* Adjust the box shadow as needed */
}


.green{
  background-color: #5B845B;
}

.grey{
  background-color: #515050;
}

.black{
  background-color: #212020;
}

.orange{
  background-color: #E6562A;
}

.white{
  background-color: white;
  border: black solid 2px;
}

@media screen and (max-width: 840px) {
  .white-box {
    width: 100% !important;
  height: 310px !important;
    /* background-color: rgb(255, 255, 255); */
  }
  .container_table img{
    width: 20%;
    margin-right: 1em;
    
  }
  .circle_main{
    width: 20%;
    padding: 0em;
  }
  .white-box img{
    height: 310px;
  }
  .hovering{
    visibility: hidden;
  }

    .green_evvehicle img:nth-child(2),
    .green_evvehicle img:nth-child(3) {
      display: none; /* Hide the second and third images for smaller screens */
    
  }
  .green_evvehicle img {
    width: 150%;
    /* margin-top: 1rem; */
    height: auto;
    
  }
  .blacks-container{
    display:block;
  }
  .left_image {
    width: 30%;
    margin-right: 0rem;
  }
  .main_content_color{
    justify-content:center;
  align-items: center;
  margin: 0 auto;
  }
  .main_content_color h2 {
    padding: 1rem;
    font-weight: 500 !important;
    font-size: 20px;
}
.lower_heading h3 {
  font-weight: 600 !important;
  font-size: 16px;
  /* padding-left: 2rem !important; */
}
.left_image{
  flex: 2;
}
.right_image{
 flex: 2;
}
.buttons{
  width: 50%;
}
}

.main_content_color h2{
  font-weight: 600;
}
.main_head{
  display: block;
  position: relative;
}
.main_head h3{
  font-size: 26.5px;
}
.lower_heading{
  padding-top: 2rem;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-family: Helvetica;
 
 
}
.lower_heading span{
  color: orange;
}
.lower_heading h3{
  text-align: center;
  font-weight: 700;
  justify-content: center;
  margin: 0 auto;
  font-size: 32px;
  color: #E6562A;
  text-transform: uppercase;
  font-family: Helvetica;
  /* padding-left: 2rem !important; */
}


@media screen and (max-width:840px){
  .buttons {
    font-family: Helvetica;
    padding: 0.3em;
    border:2px solid #ffffff;
    border-radius: 20px;
    display: block !important;
    width: 100%;
  }
  .book_now{
    width: 70%;
  }
  .content h4 {
    font-size: 17px !important;
    text-align: center !important;
  }

  .tables{
    display: block ;
    padding: 2rem;
    margin-bottom: 0rem;
  }
  .container_table,.containers_table {
    width: 100%;
    margin-bottom: 0rem;
    
  }
  .containers_table{
    margin-top: 2rem;
  }
}

@media screen and (max-width:1280px) {


  .green_evvehicle img {
    margin: -15rem;
   
  }
}
@media screen and (max-width: 840px) {
  .hs_visible{
    display: flex !important;
  }
  .logo_part{
    display: none;
  }
  .white-box {
    /* padding-top: 3rem; */
    width: 70%;
    margin-top: 3rem;
  height: 400px;
    background-color: rgb(255, 255, 255);
  }


    .green_evvehicle img:nth-child(2),
    .green_evvehicle img:nth-child(3) {
      display: none; /* Hide the second and third images for smaller screens */
    
  }
  .green_evvehicle img {
    width: 150%;
    /* margin-top: 1rem; */
    height: auto;
    
  }
  .blacks-container{
    display:block;
  }
  .left_image {
    width: 20%;
    margin-right: -1rem;
  }
  .left_image img{
    width:70% ;
  }
  .right_image img{
    width: 70%;
  }
  .main_content_color{
    justify-content:center;
  align-items: center;
  margin: 0 auto;
  }
  .main_content_color h2 {
    padding: 1rem;
    font-weight: 500 !important;
    font-size: 20px;
}
.lower_heading h3 {
  font-weight: 600 !important;
  font-size: 16px;
  /* padding-left: 2rem !important; */
}
.left_image{
  flex: 2;
}
.right_image{
 flex: 2;
}
}
@media screen and (max-width: 840px) {
  .hs_visible{
    /* display: none !important; */
  }
  .tables{
    display: block !important ;
    padding-left: 0em !important;
    margin-bottom: 0rem;
    padding-right: 2rem !important;
  }
  .containerss_table{
    display: none;
  }
}

@media screen and (max-width:600px) {
    .white-box img{
      width: 150% !important;
    }
    .book_now button{
      width: 100%;
      font-size: 11px;
    }
    .orange_ev img{
      margin-top: 0rem;
    }
    .tables{
      display: block !important ;
      padding-left: 0em !important;
      margin-bottom: 0rem;
      padding-right: 2rem !important;
    }

    .safety span{
      padding-top: 11rem !important;
      font-size: 70px !important;
    }
    .containerss_table{
      display: none;
    }
    .left_image {
      margin-left: 3rem;
     padding-top: 1em;
    }
    .left_part{
      display: block !important;
    }
    .left_part img{
      width: 200% !important;
      /* padding-left: 2em ; */
      padding-bottom: 2em;
    }
    .right_part{
      display: block !important;
    }
    .right_part img{
      /* padding-left: 2em !important; */
      padding-bottom: 2em;
      width: 200% !important;
    }
    .circle{
      width: 35px;
      height: 35px;
    }
  .image_new{
    width: 100% !important;
  }
}


.taleido_headings{
  /* position:relative; */
  /* display:inherit; */
  margin-top: 2em;
  
}
 

/* table */

.logos{
  display: flex;
  position: relative;
  align-items: center;
  /* justify-content:space-evenly; */
  /* margin: 0 auto; */
}

.left_image {
  width: 100%;
/* padding-left: 15em; */
}
.left_image img{
  width: 50%;
  padding-left:10em ;
}
.right_image img{
  width: 35%;
  margin-left: -20em}
.right_image {
  width: 100%;
  /* margin-left: 8em; */
}

.first{
  width: 10% !important;
}
.container_table img{
  width: 20%;
  height: auto;
}
.containerss_table img{
  width: 60%;
  padding: 0.3em;
  /* margin-left: 5em; */
}

.container_table h1 {
  font-size: 3em;
  font-weight: 300;
  line-height: 1em;
  text-align: center;
  font-family: Helvetica;
  /* color: #4dc3fa; */
}

.container_table h2 {
  font-size: 1em;
  font-weight: 300;
  text-align: center;
  display: block;
  line-height: 1em;
  padding-bottom: 2em;
  font-family: Helvetica;
  color: #000000;
}

.container_table  a {
  font-weight: 700;
  text-transform: uppercase;
  font-family: Helvetica;
  color: #ffffff;
  text-decoration: none;
}

.blue {
  color: #74c8ef;
}
.yellow {
  color: #fff842;
}

td img{
  width: 60% !important;
  /* padding-top: 2em !important; */
}

.container_table th h1,.containers_table th h1 {
  font-weight: bold;
  font-size: 1em;
  text-align: left;
  color: #185875;
}

.container_table td,.containers_table td {
  font-weight: normal;
  font-size: 1em;
  -webkit-box-shadow: 0 2px 2px -2px #0e1119;
  -moz-box-shadow: 0 2px 2px -2px #0e1119;
  box-shadow: 0 2px 2px -2px #0e1119;
}
.tables{
  display: flex;
  padding: 1rem;
  padding-left: 5em;
}
.container_table,.containers_table {
  text-align: left;
  overflow: hidden;
  display: table;
  margin-left: 1em;
}
.container_table td,.containers_table td,
.container_table,.containers_table {
  border: 1px solid rgba(255, 255, 255, 0.511);
  padding-bottom: 2%;
  padding-top: 3%;
  padding-left: 2%;
}

.container_table,
.containers_table {
border-radius: 20px 0 0 20px;
}

/* Background-color of the odd rows */
.container_table tr:nth-child(odd),
.containers_table tr:nth-child(odd) {
  background-color: #4d4d4f26;
}

/* Background-color of the even rows */
.container_table tr:nth-child(even),
.containers_table tr:nth-child(even) {
  background-color: #0e0e0ea5;
}

.container_table th,
.containers_table th {
  background-color: #090a0c;
}

.container_table td:first-child {
  color: #ffffff;
}
.container_table td:nth-child(2) {
  color: #e8e8e8d7;
}
.containers_table td:first-child {
  color: #e8e8e8d7;
}
.container_table td, .containers_table td{
  height: 10%;
}
.grey_img{
  position: relative;
  width: 110%;
  margin-right: -10rem !important;
}
.grey_img img{
 width: 120%;
 
}

/* book now and apply now */

.left_part{
  /* display: block;  */
}

.image_new{
position: relative;
width: 60%;
/* padding-right: 1em; */
justify-content: center;
align-items: center;
margin: 0 auto;
/* display: flex; */
}
.image_new img{
  width: 100%;

  justify-content: center;
align-items: center;
margin: 0 auto;
  /* padding-left:1em ; */
}
.right_part{
  display: block;
 
}

.buttons {
  /* border:2px solid #ffffff6e; */
  border-radius: 20px;
  display: flex;
  width: 88.5%;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  transition:  font-size 3s ease;
}

.buttons button:hover{
  font-size: large;
}
.left_part, .right_part {
  margin: 0 auto;
  padding-top: 2rem;
  position: relative;
}

.separator {
  top: 0;
  bottom: 0;
  left: 50%;
  /* border-left: 2px solid #ffffff; Adjust the color as needed */
  content: '';
}

.details{
  padding-top: 2em;
  position: relative;
  display:flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.content{
  display: block;
}
.details h4 {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

.book_now {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.book_now button {
  color: rgb(255, 255, 255);
  background-color: #E6562A;
  padding: 0.8rem;
  width: 300px;
  border-radius: 20px;
  padding-top: 1rem;
  transition: background-color 0.3s ease;
}

.book_now button:hover{
  background-color: #FF7F50; 
}

.button_group{
  /* margin-top: -7rem; */
  padding-bottom: 2rem;
}

/*logo hs hsplus*/
.hs_visible{
  display: none;
  justify-content: center;
  align-items: center;
}
