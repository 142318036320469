.header {
    width: 100%;
    background-color:black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;

  }
  
  .nav {
    height:fit-content;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
  }
  
  .nav__logo {
    /* color: var(--title-color); */
    align-items: center;
    column-gap: 0.25rem;
    font-weight: var(--font-medium);
    transition: 0.3s;
    top: 1;
    left: 1;
  }

  
  .nav__logo img {
    width: 270px;
    height: auto;
    margin-left: -2.3rem;
  }
  
  .nav__logo:hover {
    color: #E6562A;
  }
  .nav__list {
    display: flex;
    flex-direction: row;
    font-family: Helvetica;
    /* text-align: center; */
  }


  @media screen and (max-width: 830px) {
    .nav__menu {
      position: fixed;
      background-color: hsla(0, 0%, 0%, 0.808);
      top: 0;
      right: -100%;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(96px);
      transition: 0.3s;
    }
    .nav__list {
        display: block;
        flex-direction: column;
        padding: 4rem;
        padding-top: 4rem;
        text-align: center;
      }
      .nav__list li{
        padding: 2.5rem;
      }
  
  
    .nav__logo img {
      width: 200px;
      height: auto;
    }
  
    .show-menu .nav__menu {
      right: 0;
    }
    .nav {
        height: 80px;
      }
      .nav__logo img {
      
        margin-left: -1rem;
      }
      
  }
  
  @media screen and (min-width: 830px) {
    .nav__toggle,
    .nav__close {
      display: none; /* Hide the icons on big screens */
    }
  
    .nav__menu {
      display: flex;
      flex-direction: row; /* Set the menu bar horizontal on big screens */
      align-items: center; /* Center menu items horizontally */
    }
    .nav__list {
      margin: 0; /* Remove any margin from the list items */
    }
    .nav__item {
      margin: 0 1rem; /* Add some spacing between menu items */
    }

  }
  
  
  .nav__link {
    /* text-transform: uppercase; */
    color: var(--title-color);
    font-size: 17px;
    font-weight: var(--font-medium);
    transition: 0.3s;
  }
  
  .nav__link:hover {
    color:#E6562A;;
  }
  
  .nav__close {
    font-size: 2rem;
    color: var(--white-color);
    position: absolute;
    top: 1rem;
    cursor: pointer;
  }
  
  .show-menu {
    right: 0;
  }
  
  .show-menu .nav__menu {
    right: 0;
  }

  .nav__toggle i{
    font-size: 40px;
  }

  .nav__close i{
    font-size: 40px;
  }