/* Media.css */

.Media {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: space-around;
    margin: 0 auto;
    align-items: center;
    padding-left: 65px;
    padding-right: 65px;
    padding-bottom: 2rem;
    background-color: rgb(38, 36, 36);

  }

  .main_cont{
    display: flex;
    
  }
  
  .ImageCard {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ImageCard img {
    width: 100%;
    /* height: 100%; */
    /* opacity: 1; */
   
  }
  
  .text-xl {
    font-family: Helvetica;
    font-size: 1.25rem;
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  

  
  .space-x-4 {
    display: flex;
    justify-content: space-between;
  }
  
  .btn {
    padding: 0.5rem 1rem;
    background-color: #4caf50; /* Green background color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  /* Add any other styles as needed */
  
  /* ImageCard.css */

.ImageCard {
    position: relative;
    max-width: 280px;
    height: 300px;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }
  
  .ImageCard:hover {
    transform: scale(1.1) !important;
  }
  
  .ImageCard img {
    width: 100%;
    /* height: auto; */
   
  }
  
  .absolute {
    position: absolute;
    inset: 0;
  }
  
  /* .flex {
    display: flex;
  } */
  
  .items-end {
    align-items: flex-end;
  }
  
  .bg-gradient {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
  }
  
 
  
  .text-white {
    color: white;
  }
  
  /* Add any other styles as needed */
  
  .text{
    margin-top:50%;
    font-family: Helvetica;
  }

@media screen and (max-width:840px) {
  .Media {
    flex-direction: column;
    padding: 5px;
  }
  .ImageCard {
    width: 100%; /* Set cards to be 50% of the width with some margin */
    margin: 1rem;
  }
  .ImageCard {
   margin-top: 1.5rem;
  }
}


@media screen and (max-width:1280px) {

  .ImageCard {

    margin: 1rem;
    /* padding:0.1em; */
  }

}