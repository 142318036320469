/* about.css */
body {
  overflow-x: hidden;
}

.black-container {
  display: flex;
  position: relative;
  background-color: rgb(38, 36, 36);
  justify-content: space-around;
  align-items: center;
}

.green-box { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 550px;
  background-color: #8CBD77;
}

.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 550px;
  background-color: rgb(255, 255, 255);
}
.hundred{
  position: absolute;
 
}
.indigineous{
  position: absolute;
}
.indigineous h3{
  font-size: 95px;
  margin-top: 25rem;
  z-index: 900;
  position:sticky;
  font-family: 'Anton', sans-serif;
  font-weight: 600;
  -webkit-text-stroke: 0.6px rgba(255, 255, 255, 0.616);
  color: transparent;
}

.hundred img{
  z-index: 2;
  width: 90%;
  margin-left: 5rem;
  margin-bottom: 13rem;
}

.green_ev img {
  position: absolute;
  max-width: 150%;
  width: 130%;
  max-height: 150%;
  margin-left: -5rem;
  opacity: 1;
}

.wrapper img {
  opacity: 0.10;
}

.zmdi {
  display: block;
  margin: -9rem 1rem;
  
}

i::before {
  font-size: 290px;
  z-index: -1;
  color: #898989a0;
  -webkit-text-stroke: 0.8px #ffffff47;
  animation: glowing 5s reverse infinite ease-in-out;
}

i:nth-child(4)::before {
  animation-delay: 0s;
}

i:nth-child(3)::before {
  animation-delay: 0.14s;
}

i:nth-child(2)::before {
  animation-delay: 0.28s;
}

i:nth-child(1)::before {
  animation-delay: 0.35s;
}

@keyframes glowing {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.7;
    z-index: -1;
    filter: drop-shadow(0 0 0 hsla(0, 0%, 100%, 0)) drop-shadow(0 0 1px #ffffff05) drop-shadow(0 0 2px #9d9d9d04) drop-shadow(0 0 3px #9d9d9d17) drop-shadow(0 0 4px #9d9d9d00) drop-shadow(0 0 5px #9d9d9d01);
  }
}

.main_content {
  position: absolute;
  width: inherit;
  z-index: 2;
  padding: 3rem;
}

.main_content h1,
h2,
h4 {
  
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.main_content h1{
  background: linear-gradient(to right, #FF9933, #FFFFFF, #138808); /* Indian flag colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
 font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke: 2.5px #00000065;
  font-size: 65px !important;
  font-weight: 600;
}

.main_content h2{
  background: linear-gradient(to right, #FF9933, #FFFFFF, #138808); /* Indian flag colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2.5px #0000005b;
  font-size: 50px;
  font-weight: 500;
}

.head_content {
  align-items: center;
  text-align: center;
}

.content h6{
  padding: 1rem;
  padding-top: 6rem;
  font-size: 20px;
  color: black;
  font-weight:lighter;
  font-family: Arial, Helvetica, sans-serif;
  padding: 1rem;
}


@media screen and (max-width:568px) {
  .black-container {
    flex-direction: column;
    /* height: 100vh !important; Set height to auto for responsiveness */
  }
  .indigineous h3{
  
      font-weight: 600;
      -webkit-text-stroke: 2px rgba(255, 255, 255, 0.616);
      color: transparent;
    
    font-size: 58px !important;
    margin-top: 15rem !important;
    margin-left: 3.7rem !important;
  }
  .hundred img{
    z-index: 2;
    width: 70% !important;
    margin-left: 3rem !important;
  
    margin-bottom: 8rem !important;
  }

  .green-box {
    width: 100% !important;
    height: auto !important;
    /* margin-top: 3rem !important; */
    margin-left: 0rem !important;
    align-items: flex-start !important; /* Align items to the start of the container */
  }
  .main_content h1{
    font-size: 34px !important;
    -webkit-text-stroke: 1.5px #00000065;
  }
  .main_content h2 {
    font-size: 35px !important;
}
.content h6 {
  font-size: 15px !important;
}
.green_ev img {
  max-width: 120% !important;
  max-height: 120% !important;
  margin-left: -2rem !important;
}
.main_content h2{
  -webkit-text-stroke: 1.3px #0000005b;
  /* -webkit-text-stroke: 2px #e0ebd3; */

}
}
/* 
@media screen and (max-width:1250px){
  .green-box {
    width: 100%;
    height: 450px;
    background-color: #8CBD77;
    margin-left: 4rem;
  }
  
  .content-box {
    width: 100%;
    height: 450px; 
  }
 

  .indigineous h3{
    font-size: 95px;
    margin-top: 22rem;
  }
  
  .green_ev img {
    width: 140%;
  }
  
  .main_content h1{
    font-size: 65px;
  }
  
  .main_content h2{
    font-size: 45px;
  }
  
  .content h6{
    font-size: 17px;
  }
} */

@media screen and (max-width: 840px) {
  .indigineous h3{
    font-size: 130px;
    margin-top: 25rem;
     }

  .black-container {
    position: relative;
    flex-direction: column;
    padding-top:0rem !important ;
    height: auto; /* Set height to auto for responsiveness */
  }

  .green-box {
    width: 100% !important;
    height: auto;
    margin-bottom: 0;
    margin-left: 0rem;
    align-items: flex-start; /* Align items to the start of the container */
  }
  .green_ev img {
    position: relative;
    margin-left: 0rem;
    max-width: 100%;
    max-height: 100%;
    z-index: 100;
    opacity: 1;
    align-self: flex-start; 
  }

  .content-box {
    width: 100%;
    margin-top: 0rem !important;
    margin-right: 0rem;
    align-items: center;
  }

  .main_content {
    position: absolute;
    width: inherit;
    z-index: 2;
    padding: 0.5rem;
  }
  
  .main_content h1,
  h2,
  h4 {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .main_content h1{
    font-size: 60px;
    font-weight: 600;
  }
  
  .main_content h2{
    font-size: 50px;
    font-weight: 300;
  }
  
  .head_content {
    align-items: center;
    text-align: center;
  }
  
  .content h6{
    padding: 1rem;
    padding-top: 1rem;
    font-size: 20px;
    font-weight: 200;
  }

  .zmdi {
    margin: -9rem 1rem;
  }

  i::before {
    font-size: 250px;
  }
}


@media screen and (max-width:360px) {
  .indigineous h3{
    font-size: 50px !important;
    margin-top: 13rem !important;
    margin-left: 3.5rem !important;  
  }
  .content-box{
    height:600px;
  }
  
}
