.T_header_img img {
    width: 100%;
}

.container_taliedo{
    width: 100%;
    height: 100%;
    background-color: black;
}
#kaleidoT-header{
    padding: 0;
}

.about_taleido h3,p{
  color: rgb(255, 255, 255);
}
.text-without-icon-taleido{
    color: rgb(255, 255, 255);
}
#reveling-heading{
    border-bottom: none;
    font-family: var(--heading-family) !important;
    font-size: 2.25rem;
    letter-spacing: 3px;
    text-align: center;
    font-weight: 400;
    margin: 1rem 1rem;
    color: var(--icon-color);
    margin-bottom: 50px;
    margin-top: 50px;
}
.taleido_heading {
    text-align: center;
    margin: 1rem 1rem;
}

.taleido_lec {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 3rem 2rem;
}

.taleido_img {
    display: flex;
    justify-content: center;
    /* margin-bottom: 50px; */
}
.taleido_img > img {
    position: relative;
  }
  
  /* Initial state */
  [data-aos="GuestAnimLeft"] > img {
    left: 50%;
    transition-property: left;
    transition-duration: 1800ms;
    transition-delay: 100ms;
  }
  
  /* Final state */
  [data-aos="GuestAnimLeft"].aos-animate > img {
    left: 0;
  }
  [data-aos="GuestAnimRight"] > img {
    right: 50%;
    transition-property: right;
    transition-duration: 1800ms;
    transition-delay: 100ms;
  }
  [data-aos="GuestAnimRight"].aos-animate > img {
    right: 0;
  }

.taleido_img img {
    width: 70%;
    background: #ffffff0a;
    padding: 14px;
}

.taleido_img_style {
    justify-content: flex-end;
}

.taleido_img1 {
    padding-left: 85px;
}

.taleido_heading {
    display: flex;
    /* margin-left: 0; */
}

.taleido_name {
    border-bottom: 2px solid var(--icon-color);
    padding-bottom: 6px;
    color: var(--white);
    text-align: left;
}

.about_taleido_para2 {
    display: none;
}

.about_taleido_para1{
    margin-left: 15%;
}
.taleido_img_style{
    position: relative;
    right: 15%;
}
.para1 {
    margin-right: 15%;
}
.yt-link-part{
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
    padding: 8px;
}
.yt-link-part:hover{
    color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
}

@media screen and (min-width: 999px) {
    .about_taleido {
        text-align: justify;
    }

    .taleido_name {
        /* font-size: 1.6rem; */
        font-size: 1.39rem;
    }
    
}

@media screen and (max-width: 767px) {
    .taleido_heading {
        justify-content: center;
    }

    .taleido_name {
        text-align: center;
        letter-spacing: 1px;
    }

    .about_taleido {
        text-align: center;
    }

    .taleido_lec {
        display: block;
        width: 100%;
        padding: 1rem 2rem;
    }

    .about_taleido_para1 {
        display: none;
    }

    .about_taleido_para2 {
        display: block;
    }

    .taleido_img {
        margin-bottom: 1rem;
    }

    .taleido_img img {
        width: 100%;
    }

    .header_img img {
        padding-bottom: 20px;
    }

    .kaleido_header {
        padding-top: 20px;
        padding-bottom: 0;
    }
    .about_taleido{
    margin-left: 0;
}
.taleido_img_style{
    position: relative;
    right: 0%;
}
.para1 {
    margin-right: 0%;
}
}

@media screen and (max-width: 996px) and (min-width:768px) {
    .taleido_img {
        justify-content: center;
        margin-bottom: 2rem;
    }

    .taleido_lec {
        display: block;
        width: 100%;
        text-align: center;
    }

    .about_taleido_para1 {
        display: none;
    }

    .about_taleido_para2 {
        display: block;
    }

    .taleido_heading {
        justify-content: center;
    }
    .taleido_heading h3{
        text-align: center;
    }

    .taleido_lec {
        padding: 2rem;
    }

    .taleido_name {
        font-size: 1.9rem;
    }
    .about_taleido{
    margin-left: 10%;
    width: 90%;
}
.taleido_img_style{
    position: relative;
    right: 0%;
}
}

@media screen and (max-width: 1199px) and (min-width: 999px) {

    /* .taleido_name{font-size: 1.4rem;} */
    .taleido_lec {
        padding: 3rem 0rem;
    }
.taleido_img img {
    width: 90%;
    background: #A8A8A80A;
    padding: 14px;

}
.about_taleido{
    margin-left: 5%;
}
.taleido_img_style{
    position: relative;
    right: 0%;
}
.para1 {
    margin-right: 0%;
}

}

@media screen and (max-width: 518px) and (min-width: 410px) {
    .taleido_name {
        font-size: 1.4rem;
        letter-spacing: 0px;
    }
        .about_taleido{
    margin-left: 0;
}
.taleido_img_style{
    position: relative;
    right: 0%;
}

}

@media screen and (max-width: 480px) and (min-width: 447px) {
    .prev_taleido_name {
        letter-spacing: 2px;
    }
}

@media screen and (max-width: 409px) and (min-width: 380px) {
    .taleido_heading {
        margin: 1rem;
    }
}

@media screen and (max-width: 338px) {
    .taleido_name {
        font-size: 1.2rem;
        letter-spacing: 2px;
    }
}

.taleido_lec p {
    text-align: justify;
    color: rgb(255, 255, 255);
    text-transform: none;
}

/* added by Abdeali */

/* #Kaleido .fa{
    color: var(--white) !important;
} */
.text-without-icon{
    margin-top: 20px;
    font-size: 1.05rem;
}
