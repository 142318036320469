ul {
  margin: 0px;
  padding: 0px;
}
.container_footer{
  overflow: hidden;
  background-color: #000000;
}
.rebel h3{
  position: absolute;
margin-top: 2.5rem;
font-family: Helvetica;
  color: #ffffff;
}
.footer-section {
  overflow: hidden;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  padding: 1em;
  margin-top: 8px;
}
.cta-text {

  padding-left: 19px;
  display: inline-block;
}
.cta-text h3{
  /* padding: 1em; */
  color: #E6562A;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.second_part{
  padding-left: 0em !important;
  padding-right: 2em !important;
}

.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
 padding: 10px;
}
.footer-logo img {
  max-width: 250px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 16px;
  text-align: end;
  color: #d6d6d6;
  line-height: 10px;
}
.footer-text p span{
  font-weight: 700;
  color: white;
}
.footer-social-icon span {
  text-align: end;
  color: #fff;
  display: block;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
  font-weight: 700;
  margin-top: 2em;
}
.footer-social-icon {
  text-align: end;
  color: #fff;
  display: block;
  font-family: "Poppins", sans-serif;
  margin-bottom: -20px;
  margin-top: 4em;
}
.footer-social-icon a {
  color: #fff;
  font-size: 30px;
  /* margin: 20px; */
  align-items: end;
  padding-left: 1em;
}
a .special{

  margin-right: 20px !important;
 
}

.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #E6562A;
}
.footer-widget-heading h3 {
  color: #fff;
  /* text-align: end; */
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.address h3{
  text-align: end;
}
/* .footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
} */
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #E6562A;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #E6562A;
  padding: 13px 20px;
  border: 1px solid #E6562A;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a {
  color: #E6562A;
  font-family: Helvetica;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
  font-family: Helvetica;
}
.footer-menu li:hover a {
  color: #E6562A;
  font-family: Helvetica;
}
.footer-menu li a {
  font-size: 14px;
  font-family: Helvetica;
  color: #878787;
}


.rebel{
  background-color: rgb(38, 36, 36);
}

.qr{
  width: 30%;
  margin-left: 3em;
}

@media screen and (max-width:810px) {
  .rebel h3{
    padding-top: 0.5em;
    font-size: 13px ;
  }
}

@media screen and (max-width:390px) {
  .rebel h3{
    font-size: 20px !important;
    padding-left: 0em !important;
  }
}
@media screen and (max-width:568px) {
  .address h3{
    text-align: start;
  }

  .footer-text p {
   
    text-align: start;
    font-size: 13px;
    color: #878787;
 
  }


.footer-text p span{
  color: #878787;
}
  .footer-social-icon span {
    text-align: start;

  }
  .footer-social-icon {
    text-align: start;
    

  }
  .footer-social-icon a{
   color:#878787 ;
   padding-left: 0em;
   padding-right: 1em;
   font-size: 20px;
  }
  .cta-text h4 {
    font-size: 15px;
 
  }
  .rebel h3{
    font-size: 20px !important;
    padding-left: 1em;
  }
  .footer-widget-heading h3 {
    color: #bebebe;
    margin-top:1em;
    /* text-align: end; */
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;

  }

  .footer-widget ul{
    padding-left: 0rem ;
  }
  .footer-social-icon span{
    color: #bebebe;
  }
  .qr{
    justify-content: center;
    margin: 0 auto;
    width: 30%;
    /* margin-left: 3em; */
  }
  .second_part{
    padding-left: 1em !important;
  }
  
}

@media screen and (max-width:1280px){
  .rebel h3{
    font-size: 20px ;
  }
}