/* .swapping-squares-spinner, .swapping-squares-spinner * {
    box-sizing: border-box;
  }

  .swapping-squares-spinner {
    height: 65px;
    width: 65px;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    align-items: center;
    position: relative;
    display: flex;
    margin-top: 20rem;
    flex-direction: row;
  }

  .swapping-squares-spinner .square {
    height: calc(65px * 0.25 / 1.3);
    width:  calc(65px * 0.25 / 1.3);
    animation-duration: 1000ms;
    border: calc(65px * 0.04 / 1.3) solid #E6562A;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    animation-iteration-count: infinite;
  }

  .swapping-squares-spinner .square:nth-child(1) {
    animation-name: swapping-squares-animation-child-1;
    animation-delay: 500ms;
  }

  .swapping-squares-spinner .square:nth-child(2) {
    animation-name: swapping-squares-animation-child-2;
    animation-delay: 0ms;
  }

  .swapping-squares-spinner .square:nth-child(3) {
    animation-name: swapping-squares-animation-child-3;
    animation-delay: 500ms;
  }

  .swapping-squares-spinner .square:nth-child(4) {
    animation-name: swapping-squares-animation-child-4;
    animation-delay: 0ms;
  }

  @keyframes swapping-squares-animation-child-1 {
    50% {
      transform: translate(150%,150%) scale(2,2);
    }
  }

  @keyframes swapping-squares-animation-child-2 {
    50% {
      transform: translate(-150%,150%) scale(2,2);
    }
  }

  @keyframes swapping-squares-animation-child-3 {
    50% {
      transform: translate(-150%,-150%) scale(2,2);
    }
  }

  @keyframes swapping-squares-animation-child-4 {
    50% {
      transform: translate(150%,-150%) scale(2,2);
    }
  } */

  .swapping-squares-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Make the spinner cover the entire viewport height */
  }
  
  /* Adjust the width and height of the animation container as needed */
  .lottie-animation-container {
    width: 200px; /* Adjust width as needed */
    height: 200px; /* Adjust height as needed */
  }
  