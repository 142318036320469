.blackk-box{  
  display: flex; /* Use flex display for .right-box */
  flex-direction: column; /* Set the direction to column for proper stacking */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  width: 100%;
  height: 550px;
  background-color: rgb(255, 255, 255);
  /* margin-right: 4rem; */
}

.snowflake{
  position: absolute;
}
.snowflake{
  opacity: 0.15;
}

.fireflames{
  position: absolute;
}
.fireflames img{
  filter: 1;
}
.green_ev img{
position:relative;
z-index: 5;
}

.safety{
  position: absolute;
}
.safety span{
  font-size: 95px;
  padding-top:32rem  !important;
   margin-left: 10rem;
  z-index: 45 !important;
  /* font-size: ; */
}
.blue-box{
  position: relative;
  display: flex; /* Allow flex properties to take effect */
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 550px;
  background-color: #004AAD;
  /* margin-left: 4rem; */
}
  
  .black-box {
    display: flex; /* Allow flex properties to take effect */
    width: 100%;
    height: 550px;
    justify-content: flex-start;
    background-color: rgb(255, 255, 255);
    /* margin-left: 4rem; */
  }
  
  .orange-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 550px;
    background-color: #F6744C;
    /* margin-right: 4rem; */
  }
  
.wrapperss{
    margin: 7rem 10rem; 
}
  
  .zmdi {
    display: block;
    
  }

  .orange_ev img {
    width: 71%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 150%; 
    max-height: 150%;
    margin-top: 3rem;
    margin-inline-start: 14rem;  
  }

 
  @media screen and (max-width: 840px) {

    
    .orange_ev span {
      z-index:-1;
      -webkit-text-stroke: 1px rgb(255, 255, 255);
      margin-left:0em;
      font-size: 100px ;
      padding-left: 15rem !important;
      /* margin-top: -4rem; */
      font-weight: 400 !important; /* Adjust the font size as needed */
    }
    
    .black-box {
      width: 100%;
      margin: 0 auto;
      background-color: rgb(255, 255, 255);
    }
  
    .blue-box {
      width: 100%; /* Adjust width for large screens */
      height: 100%; /* Adjust height to fill the container */
     /* margin-top: 4rem; */
      margin-right: 0rem;
      margin-left: 0rem;
    }
    .orange-box {
    width:100%;
    height:550px !important;
    margin-right: 0rem;
    /* margin-top: 1rem; */
    }
    .orange_ev img {
      position: relative;
    margin-left: 0rem;
    overflow-x: hidden;
    /* width: 200%; */
    max-width: 100%;
    width: 140%;
    /* max-width: 100%; Ensure the image doesn't exceed the container's width */
    height: 0 auto; /* Ensure the image doesn't exceed the container's height */
    /* margin-left: -20rem; */
    z-index: 100;
    opacity: 1;
    
    }
  
  
  
    i::before {
      font-size: 220px;
    }

    .blackk-box{
      /* overflow-x: hidden; */
    /* margin-top: 4rem; */
      width: 100%;
      /* height: 100%; */
      /* background-color: rgb(0, 0, 0); */
      margin-right: 0rem;
    }

    .wrapperss{
      justify-content: center;
      margin: 0 auto; 
  }
  }

  .main_content_orange
{
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

  .solid_safety h4{
    /* position: absolute; */
    display: block;
    font-family: Arial, Helvetica, sans-serif;
  /* font-weight: 600; */
    margin: 0 auto;
    text-align: center;
    color: black;
    font-size:30px;
    font-weight: 500;
    padding-top: 0rem  !important;
    /* padding: 1rem; */
    -webkit-text-stroke: 1px rgb(0, 0, 0);
  }

  .liquid h4 {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    margin-bottom:-0.3em ;
    font-size: 57px !important;
    font-weight: 700;
    /* display: inline; */
    text-align: center;
    color: #004bad;
  }

  .liquid span{
    font-size: 37px;
    
  }

  .points_orange{
    margin-top: 2rem;
  }

  .points_orange h4{
    font-size: 23px;
    color: black;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.5rem;
  }
 
  
  .points_orange h4::before {

    content: '\2022'; /* Unicode character for a bullet point */
    margin-right: 10px; /* Adjust the spacing between the bullet point and the text */
  }
  


.orange_ev {
  position: relative;
}

.orange_ev span {
  position: absolute;
  padding-top:7rem ;
  z-index:-1;
  font-family: 'Anton', sans-serif;
  font-size: 130px;
  padding-left: 25rem;
  font-weight: 500; /* Adjust the font size as needed */
  -webkit-text-stroke: 4px rgba(255, 255, 255, 0.689);
  color: transparent;
  align-items: center;
  text-align: center;
}

.KMS h1{
  padding: 1em;
  
  /* background: linear-gradient(to right, #FF9933, #FFFFFF, #138808); Indian flag colors */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
 font-family: Arial, Helvetica, sans-serif;
  /* -webkit-text-stroke: 2.5px #00000065; */
  color: black !important;
  font-size: 45px !important;
  font-weight: 600;
  align-items: center;
  text-align: center;
  margin-bottom: -1em;
}
.main_contents{
  padding-top: 0em;
 
}



.KMS h2{
  color: #004AAD;
  font-size: 40px;
  font-weight: 500;
  /* padding-bottom: 2em; */
  text-align: center;
}

.warranty{
  padding: 2em;
}
.warranty span {
  margin: 0 auto;
  text-align: center;
  /* padding-top: 2rem; */
  font-size: 20px;
  /* padding: 2em; */
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  /* padding: 1rem; */
  color: rgb(0, 0, 0);
}
.award{
  position: absolute;
}

.award img{
  width: 43%;
  opacity: 0.3;
  filter: invert(1);
  z-index: -1;
  margin-top: -8rem;
}
.award img{
  z-index: -1;
  margin-left: 20rem;
  padding-top: 0rem;
}
@media screen and (max-width: 580px){
  
  .orange-box {
    width: 530px;
    height: 300px !important;
  }
  .solid_safety h2 {
color: white;
  
    font-size: 49px !important;
}

.KMS h1{

  font-size: 25px !important;

}
.main_contents{
  padding-top: 0rem;
}
.assured span{
  padding-right: 1rem !important;
  font-size: 60px !important;
  
}
.liquid h4 {
  font-size: 27px !important;
}
.liquid span {
  font-size: 21px !important;
}
.warranty span {
  font-size: 16px !important;
}
.stopnot span{
 
  font-size: 50px !important;
  padding-right: 5rem !important;
}
.KMS h2{
  font-size: 30px !important;
}


.stopnot{
  position: absolute;
  padding-top: 15rem !important;
}
.award img{
  width: 25% !important;
  z-index: -1;
  /* margin-top: -8rem; */
}
.award img{
  z-index: -1;
  margin-left: 16rem !important;
  padding-top: 0rem;
}
.orange_ev span {
  z-index:-1;
  -webkit-text-stroke: 3px rgba(255, 255, 255, 0.783) !important;
  margin-left:0rem !important;
  padding-left: 2.6rem;
  opacity: 0.6;
  font-size:70px  !important;
   padding-top: 0rem;
}
.assured{
  padding-bottom: 12rem !important;
}
}

.stopnot span{
  color: black;
  font-size: 80px;
  font-family: 'Anton', sans-serif;
  padding-right: 2rem;
  font-weight: 400;
  -webkit-text-stroke: 2.5px rgba(255, 255, 255, 0.571);
  color: transparent;
  font-style: italic;
}
.assured span{
  color: black;
  font-size: 80px;
  font-family: 'Anton', sans-serif;
  padding-right: 20rem;
  font-weight: 400;
  -webkit-text-stroke: 2.5px rgba(255, 255, 255, 0.571);
  color: transparent;
  font-style: italic;
}
.stopnot{
  position: absolute;
  padding-top: 27rem;
}
.assured{
  position: absolute;
  padding-bottom: 23rem;
}



@media screen and (max-width:1280px){
 
 
  .assured span{
   
    padding-right: 12rem;
  
  }
    .solid_safety h2 {
      font-size: 60px;
  }
  .liquid h4 {
    font-size: 38px;
  }
  .liquid span {
    font-size: 27px;
  }

  .orange_ev span {
    padding-top: 2rem;
    font-size: 110px;
     }
 .safety span{
     padding-top: 27rem !important;
 }
 
    .stopnot span{
      font-size: 80px;
      padding-right: 10rem; 
    }
    .stopnot{
      padding-top: 23rem;
    }
  
}



@media screen and (max-width: 768px) {
  /* Adjust styles for screens wider than 768px */
  .black-container {
    overflow-wrap: normal;
    position: relative;
    flex-direction: column;
    padding-top:2rem ;
    height: auto; /* Set height to auto for responsiveness */
  }

.green_ev img{
position:relative;
z-index: 5;
}
  .orange_ev{
    overflow: hidden;
    width: 100%;
    /* height: 100%; */
  }
  
  .orange_ev span {
    z-index:-1;
    -webkit-text-stroke: 1px rgb(255, 255, 255);
    /* margin-left:2rem; */
    opacity: 0.6;
    font-size:120px ;
    padding-left: 15rem;
    /* margin-top: 0rem ; */
    font-weight: 600 !important; /* Adjust the font size as needed */
  }
  
  .black-box {
    width: 100% !important;
    margin: 0 auto;
  }

  .blue-box {
    width: 100% !important; /* Adjust width for large screens */
    height: 100%; /* Adjust height to fill the container */
   margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }
  .orange-box {
  width: 100% !important;
  height:300px;
  margin-right: 0rem;
  /* margin-top: 1rem; */
  }
  .orange_ev img {
    position: relative;
  margin-left: 0rem;
  overflow-x: hidden;
  /* width: 200%; */
  max-width: 100%;
  width: 140%;
  /* max-width: 100%; Ensure the image doesn't exceed the container's width */
  height: 0 auto; /* Ensure the image doesn't exceed the container's height */
  /* margin-left: -20rem; */
  z-index: 100;
  opacity: 1;
  
  }



  i::before {
    font-size: 220px;
  }

  .blackk-box{
    /* overflow-x: hidden; */
  /* margin-top: 1rem; */
    width: 100% !important;
    /* height: 100%; */
    /* background-color: rgb(0, 0, 0); */
    margin-right: 0rem;
  }

  .wrapperss{
    justify-content: center;
    margin: 0 auto; 
}
}



@media screen and (max-width:450px) {
  .solid_safety h2 {
    font-size: 32px !important;
    
}
.liquid h4 {
  font-size: 20px !important;
}
.liquid span {
  font-size: 20px !important;
}
.orange_ev span {
 margin-top: 0rem;
  font-size: 50px !important;
 padding-left: 4rem !important;
  font-weight: 400 !important; /* Adjust the font size as needed */
  -webkit-text-stroke: 0.5px rgb(255, 255, 255);
  color: transparent;
}
.points_orange h4{
  font-size: 15px;
}

.stopnot span{
  font-size: 40px !important;
  
}
.KMS h1{
  font-size: 25px !important;
}
.KMS h2{
  font-size: 25px !important;
}
.warranty span{
  font-size: 20px !important;
}
}


.wrapper{
  position: absolute;
}