.container_about {
    position: relative;
    display: block;
  }
  .background-image{
  position:relative;
  left: 0;
  z-index: 1;
  width: 220vh;
}

.background-image img{
  width: 100%;

}
  
  
  .content-boxs {
    position: absolute;
    margin-left: 3rem;
    top: 60%;
    padding-top: 3rem !important;
    height: inherit;
    left: 2%;
     width: 45%;
    z-index: 3;
    color: black;
    transform: translate(0%, -50%);
    background-color: rgba(86, 92, 111, 0.84); /* Adjust the transparency as needed */
    padding: 20px;
    border-radius: 20px;
  }
  .content-boxs p{
    color: rgb(255, 255, 255);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  
  /* Add responsive styles as needed */
  @media (max-width: 768px) {
    .content-boxs {
      width: 80%;
      left: 10%;
    }
    .visiblee{
      display:none;
    }
    .container_about {
      position:static;
    }
  }
  
  @media (max-width: 768px) {
    
    .background-image{
      display: none;
      width: auto;
    }
    .content-boxs {
      background-color: rgba(16, 16, 16, 0.84); /* Adjust the transparency as needed */
      margin-left: 0rem;
      padding-top: 1em;
      /* position:st; */
      left: 0%;
       width: 100%;
    }
 
  }

  @media (max-width: 560px) {
 
    .content-boxs {
      /* position: relative; */
      top: 90%;
      /* padding-top: 40em; */
    }
 
  }
  