/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;500;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsl(219, 69%, 56%);
  --first-color-alt: hsl(219, 69%, 52%);
  --title-color: hsl(219, 8%, 95%);
  --text-color: hsl(0, 0%, 97%);
  --text-color-light: hsl(219, 4%, 55%);
  --white-color: #fff;
  --body-color: hsl(0, 0%, 100%);
  --container-color: hsl(0, 0%, 0%);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: 'Exo', sans-serif;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 968px) {
  :root {
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size) !important;
  background-color: var(--body-color) !important;
  color: var(--text-color) !important;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow-x: hidden !important;
  /* min-height: 100vh; */
}


ul {
  list-style: none !important;
}

a {
  text-decoration: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  max-width: 1400px;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}



.section__title {
  /* font-size: var(--h2-font-size); */
  text-align: center;
  margin-bottom: 2.5rem;
}

.main {
  overflow: hidden;
}

.shape{
  background-color: hsla(219, 33%, 22%, .5);
  filter: blur(112px);
  border-radius: 50%;
}

.shape__big{
  width: 400px;
  height: 400px;
}

.shape__small{
  width: 300px;
  height: 300px;
}
/*for small screen*/
@media screen and (max-width: 320px) {
  .container{
      margin-left: 1rem;
      margin-right: 1rem;
  }

  

}

/*for medium devices*/

@media screen and (min-width: 576px){
  .about__group{
    width: 350px;
    justify-self: center;
  }
}

@media screen and (min-width: 767px){
  .section{
    /* padding: 7rem 0 2rem; */
  }

  .nav{
    height: calc(var(--header-height)+1.5rem);
  }

  .nav__toggle,
  .nav__close{
    display: none;
  }

  .nav__list{
    flex-direction: row;
    padding-top: 0;
  }

  .nav__link{
    font-size: var(--normal-font-size);
    text-transform: initial;
  }

  .about__container{
    grid-template-columns: repeat(2, 1fr);
  }

  .logos__container{
    gap: 4rem 8rem;
  }

  .footer__container{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1040px){
  .container{
    margin-left: auto;
    margin-right: auto;
  }



  .footer__container{
    grid-template-columns: repeat(4, max-content);
    justify-content: space-between;
  }

  .footer__logo{
    column-gap: .5rem;
  }
  .footer__logo i{
    font-size: 2rem;
  }
  .footer__title{
    margin-bottom: 1.5rem;
  }
  .footer__links{
    row-gap: .75rem;
  }
  .footer__social-link{
    font-size: 1.5rem;
  }

  .footer__copy{
    margin-top: 8rem;
    padding-bottom: 1rem;
  }
}

.backtotop {
  position: fixed;
  bottom: 20px;
  right: 10px;
  padding: 20px;
  background: transparent;
  color: #929090;
  font-size: 45px;
  animation: moveUpDown 1.5s ease-in-out infinite;
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}


.blocks{
  overflow-x: hidden;
}
