*{
    box-sizing: border-box;
}


.container_dealer{
    font-family:Helvetica;
     position:relative;
     /* max-width: 900px; */
     margin-top: 10rem !important;
     width: 60%;
     justify-content: center;
     align-items: center;
     margin: 0 auto;
     border-radius: 6px;
     padding: 30px;
     margin-bottom: 2em;
     /* margin: 0 15px; */
     background-color: #fff;
     box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}

.container_dealer header{
    position: relative;
    font-size: 20px;
    font-weight: 600;
    color: #333;
}

.container_dealer header::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 3px;
    width: 27px;
    border-radius: 8px;
    background-color: #E6562A;
}

.container_dealer form{
    position: relative;
    min-height: 490px;
    background-color: #fff;
}

.container_dealer form .detailss{
    margin-top: 30px;
}
.container_dealer form .detailss.ID{
    margin-top: 20px;
}

.container_dealer form .title{
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    margin: 6px 0;
    color: #333;
}

.container_dealer form .fields{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    color: black;
}

form .fields .input-field{
    font-family:Helvetica;
    display: flex;
    width: calc(100% / 3 - 15px);
    flex-direction: column;
    margin: 4px 0;
    color: black;
}

.input-field label{
    font-family:Helvetica;
    font-size: 12px;
    font-weight: 500;
    color: #2e2e2e;
}

.input-field input{
    outline: none;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    border-radius: 5px;
    border: 1px solid #aaa;
    padding: 0 15px;
    height: 42px;
    margin: 8px 0;
}

.input-field input:is(:focus,:valid ){
    box-shadow: 0 3px 6px rgba(0,0,0,0.13);
}
.input-field select {
    outline: none;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    border-radius: 5px;
    border: 1px solid #aaa;
    padding: 0 15px;
    height: 42px;
    margin: 8px 0;
    cursor: pointer;
}

.input-field select:focus, .input-field select:valid {
    box-shadow: 0 3px 6px rgba(0,0,0,0.13);
}

.container_dealer form button, .nxtBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    max-width: 200px;
    width: 100%;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 5px;
    margin: 25px 0;
    background-color: #E6562A
}

.container_dealer form button,
.container_dealer form .backBtn{
    font-size: 14px;
    font-weight: 400;
}

form button:hover{
    background-color: #E6562A;
}

form button i,
form .backBtn i{
    margin: 0 6px;
}

form .backBtn i{
    transform: rotate(180deg);
}


form .buttons{
    display: flex;
    align-items: center;
}

form .buttons button{
    margin-right: 14px;
}



@media (max-width: 750px){
    .container_dealer{
        width: 95%;
    }
    form .fields .input-field{
        width: calc(100% / 2 - 15px);
    }

    .container_dealer form{
        overflow-y: scroll;
    }

    .container_dealer form::-webkit-scrollbar{
        display: none;
    }
    .main_bgtest{
    visibility: hidden;
    }
}

@media (max-width:550px) {
    form .fields .input-field{
        width: 100%;
    }
    
}