/* @import url('https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap'); */

.main_bgtest {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* 100% of viewport width */
    height: 100vh; /* 100% of viewport height */
    z-index: -1;
  }

  .main_bgtest img{
    width: 150%;
  }

.main_bg{
    /* position: absolute; */
    background:rgb(255, 255, 255);
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 20px;
    /* border: 2px solid #E6562A; */
    margin-top: 8rem;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}
.form-container{
    width: 80%; /* Adjusted width for responsiveness */
    max-width: 650px;
 
    /* position: relative; */
}

.form-text{
    text-align: center;
    margin: 0 0 40px 0;
}
.form-text h1 span img{
    margin: 0 10px;

}
.form-text h1{
    color: #000000;
    font-family: Helvetica;
    font-size: 40px;
    margin-bottom: 20px;
}
.form-text p{
    color: #000000;
    font-family: Helvetica;
    /* font-family: 'Akaya Telivigala', cursive; */
    font-size: 20px;
}

.main-form div{
    margin: 10px 10px;
    width: 300px;
    display: inline-block;
}
.main-form div input {
    width: 100%;
    font-family: Helvetica;
    /* font-family: 'Akaya Telivigala', cursive; */
    background: none;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.13);
    order: 1px solid #aaa;
    font-size: 20px;
    color: #000000;
    outline: none;
    padding: 3px 0 3px 10px;
    margin-top: 10px;
}
.main-form div select{
    width: 104%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.13);
    background: none;
    order: 1px solid #aaa;
    font-size: 20px;
    color: #000000;
    border-radius: 5px;
    outline: none;
    padding: 3px 0 3px 10px;
    margin-top: 10px;
}
.main-form div span{
    width: 100%;
 
    color: #000000;
    font-size: 20px;
}

#submit{
    width: 100%;
    text-align: center;
}
#submit input{
    font-family: Helvetica;
    /* font-family: 'Akaya Telivigala', cursive; */
    width: 200px;

    background-color: #E6562A !important;
    color: rgb(255, 255, 255) !important;
    transition: all .3s;
    border-radius: 20px !important;
}
#submit input:hover{
    width: 200px;
    background-color: #E6562A !important;
    color: #ffffff !important;
}
#submit input:active{
    font-size: 19px;
    background-color: rgb(46, 20, 5) !important;
    color: #ffffff !important;
}


@media screen and (max-width:710px) {
    .main-form{
        text-align: left;
    }
    .main_bg{
     width: 100%;  
    }
    .form-text span img{
        width: 13%;
    }
    .form-text h1{
        font-size: 20px;
    }

}

.background-container{
    margin-bottom: 2em;
}

